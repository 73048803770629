body {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  background: #03121a;
  color: #fff;
  padding: 64px;
}

.App {
  font-family: sans-serif;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  margin: 160px 0 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  margin: 0 0 16px;
}

.fileInput {
  position: relative;
  width: 480px;
  height: 48px;
  cursor: pointer;
}

.fileInput input {
  width: 480px;
  height: 48px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.fileInput span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 48px;
  background: #0194f3;
  color: #fff;
  border-radius: 6px;
  font-size: 20px;
}

.fileInput span.disabled {
  opacity: 0.5;
}

.download {
  margin: 16px 0 0;
  width: 480px;
  height: 48px;
  background: #ff5e1f;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 20px;
}

.download:disabled {
  opacity: 0.5;
}
